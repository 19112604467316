import React from 'react'
import Seo from "../components/Seo/seo";
import Layout from "../components/Layout/layout";
import PageBody from "../components/PageBody/pageBody";
import { Link } from 'gatsby'

const Sitemap = () => (
  <>
    <Seo slug=""/>
    <Layout>
      <PageBody h1="Sitemap">
        <ul>
          <li><Link to="/">Home</Link></li>
          <li>
            <Link to="/about-us">About Us</Link>
            <ul>
              <li><Link to="/about-us/frank-fernandez">Frank Fernandez</Link></li>
              <li><Link to="/about-us/hisato-ono">Hisato Ono</Link></li>
              <li><Link to="/about-us/ken-nagao">Ken Nagao</Link></li>
            </ul>
          </li>
          <li><Link to="/services">Services</Link></li>
          <li><Link to="/contact-us">Contact Us</Link></li>
          <li>
            <Link to="/complaint">当社の金融商品取引業に関する苦情・紛争処理体制</Link>
          </li>
          <li>
            <Link to="/disclosure">金融商品取引法に基づく説明事</Link>
          </li>
          <li>
            <Link to="/solicitation">勧誘方針</Link>
          </li>
          <li><Link to="/">Privacy</Link></li>
          <li><Link to="/">Sitemap</Link></li>
        </ul>
      </PageBody>
    </Layout>
  </>
)

// export default Sitemap
export default () => {
    if (typeof window !== 'undefined') {
        window.location = '/';
    }

    return null;
}
